import Vue from "vue";
import {
	isUndefined,
	isFunction,
	isString,
	isEqual,
	iteratee,
	identity,
	sortBy
} from "lodash";
import { _ } from "core-js";

export const get = property => state => _.get(state, property);
export const set = property => (state, payload) => _.set(state, property, payload);

export function setNested(obj, props, value) {
	const prop = props.shift();
	if (!obj[prop]) {
		Vue.set(obj, prop, {});
	}
	if (!props.length) {
		if (value && typeof value === "object" && !Array.isArray(value)) {
			obj[prop] = { ...obj[prop], ...value };
		} else {
			obj[prop] = value;
		}
		return;
	}
	setNested(obj[prop], props, value);
}

/**
 * Take a collection (Array or Object) and perform a case-insensitive search
 * and sort the results.
 */
export const searchAndSort = (
	collection,
	search,
	{ caseSensitive = false, searchIteratee = identity, sortIteratee = identity }
) => {
	const searchIsString = isString(search);
	let retval = collection;

	if (!isFunction(searchIteratee)) searchIteratee = iteratee(searchIteratee);
	if (!isFunction(sortIteratee)) sortIteratee = iteratee(sortIteratee);

	if (search && searchIteratee) {
		if (searchIsString && !caseSensitive) search = search.toLowerCase();

		retval = retval.filter(item => {
			let val = searchIteratee(item);
			if (!caseSensitive && isString(val)) {
				val = val.toLowerCase();
			}
			if (searchIsString && isString(val)) {
				return val.indexOf(search) > -1;
			}
			return isEqual(val, search);
		});
	}
	if (sortIteratee) {
		retval = sortBy(retval, sortIteratee);
	}
	return retval;
};

/**
 * Run a specific action in all modules that implement it.
 */
export async function forEachModule(modules, { dispatch, commit }, action) {
	if (isUndefined(dispatch) && isUndefined(commit)) {
		console.warn("You must provide at least one of { dispatch, commit }.");
		return;
	}

	// Automatically run the 'init' action for every module, if one exists.
	for (const moduleName in modules) {
		const moduleDefinition = modules[moduleName];

		// We only handle namespaced modules.
		if (!moduleDefinition.namespaced) continue;

		// If the action or mutation is defined on the module...
		if (
			dispatch &&
			moduleDefinition.actions &&
			moduleDefinition.actions[action]
		) {
			try {
				await dispatch(`${moduleName}/${action}`);
			} catch (e) {
				// Console log error, but don't prevent other
				// modules from initializing.
				console.error(`Error initializing module ${moduleName}`, e);
			}
		} else if (
			commit &&
			moduleDefinition.mutations &&
			moduleDefinition.mutations[action]
		) {
			commit(`${moduleName}/${action}`);
		}
	}
}