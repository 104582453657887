export default {
	install(Vue) {
		Vue.prototype.$strings = strings;
	},
};

const strings = {
	spinner: "Getting ready...",
	migration: {
		available: "Have you previously logged into the K4Community app? Click 'Start' button to login with that account."
	},
	errors: {
		generic: "Something went wrong! Please try again.",
		chat: {
			send: "Unable to send message! Please try again",
			channel: "Unable to create channel! Please try again later.",
			login: "Chat login failed! Please try again later.",
		},
		events: {
			signup: "Unable to rsvp for event! Please try again later.",
			withdraw: "Unable to withdraw from event! Please try again later.",
		},
		share: "Unable to send invite at this time.",
		form: "Unable to submit form at this time.",
		profile: "Unable to update profile at this time.",
		invalid: "Invalid redirect link. Please contact K4 support."
	},
	notRegisteredK4User:
		"No registered account was found with the credentials. Please try registering with the email first.",
	alreadyLinked:
		"The Login credentials were used with a different account. Please try with different email or account.",
	notFound: "Oops! Page not found",
	backButton: "Back",
	validation: {
		email: {
			required: "The email is required.",
			valid: "Please enter a valid email address.",
			wrong: "Please try a different email.",
		},
		password: {
			required: "Password is required.",
		},
		confirm: {
			required: "Confirm password is required.",
		},
		firstName: {
			required: "The first name is required",
			valid: "Please enter a valid first name",
		},
		lastName: {
			required: "The last name is required",
			valid: "Please enter a valid last name",
		},
		relationship: {
			required: "Please selact a relationship",
		},
		phone: {
			valid: "Please enter a valid phone number",
		},
		code: {
			required: "Please enter a code.",
			valid: `Please make sure you've entered a valid code.`,
		},
		guest: {
			required: "Please enter guests name.",
		},
	},
	label: {
		email: "Email",
		password: "Password",
		confirm: "Confirm",
		firstName: "First Name",
		lastName: "Last Name",
		phone: "Phone",
	},
	login: {
		linkButton: "Have a code?",
		linkStaff: "Staff login",
		submit: "Get started",
		password: {
			linkButton: "Forgot password?",
			submit: "Log In",
			goBack: "Go Back",
			forgot: (email) =>
				`We’ve sent an email to ${email} with a link to help verify your identity.`,
			error: {
				"auth/too-many-requests":
					"You’ve reached the maximum number of failed login attempts.\nPlease press “Forgot Password” to reset your login credentials.",
				"auth/wrong-password": "The password is incorrect. Please try again",
			},
			foundEmail: (email) => 
				`We’ve sent an email to ${email} with a 6-digit code to help us verify your identity. Please enter it below.`
		},
		differentProvider:
			"Looks like you might have logged in using a different provider previously. Please try using one of the other options.",
		mailNotExist: (email) =>
			`We could not find ${email}.\nPlease make sure you have entered a valid email address.`,
		code: {
			description: (email) =>
				`We’ve sent an email to ${email} with a 6-digit code to help us verify your identity. Please enter it below.`,
			linkButton: "Log in instead",
			submit: "Next",
		},
		staff: {
			error: `Your username and/or password were incorrect.\nPlease try again or click "Forgot Password" to reset your password.`,
			goBack: "Go Back",
			linkButton: "Forgot password?",
			submit: "Log In",
		},
	},
	loginFooter: {
		help: "Need help? Email ",
		supportEmail: "support@K4Connect.com",
		call: " or call us at ",
		phone: "855-876-9673",
		privacy: "Privacy Policy",
		url: "https://www.k4connect.com/privacy",
	},
	enterCode: {
		description:
			"If you have a 6-digit code, please enter it below. If you are unsure or need help generating one, please contact community staff for further assistance.",
		linkBack: "Have an email?",
		submit: "Get started",
		codeLabel: "6-digit code",
		invalidToken: "The code entered is not valid. Please request a new code.",
	},
	newPassword: {
		description:
			"Please create a new password that you can use to log in with next time.",
		linkButton: "Back to Login",
		submit: "Submit",
		notMatch: "Please enter the same password and confirmation.",
		error: {
			"auth/invalid-action-code":
				"Invalid password reset code. Please request a new one.",
		},
	},
	createUser: {
		description: "Sign up using a personal email.",
		submit: "Submit",
	},
	federated: {
		signup: "Or signup with any of the following accounts.",
		choose:
			"Signed up before with one of these accounts? Choose an option below.",
		error: {
			"auth/account-exists-with-different-credential":
				"An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.",
		},
	},
	logout: {
		description:
			"This will log you out of all associated K4 apps. You will be returned to the login screen where you may choose to log in again.",
		cancel: "Cancel",
		close: "Log Out",
		title: "Log Out",
	},
	invalidEnv: "CHECK ENV",
	share: {
		text: "Share K4Community Plus with friends and family.",
		description:
			"Send an invitation to a Friend or Family member so they can use K4Community Plus to receive timely notices, important information, and more from your community.",
		first: `Friend's First Name`,
		last: `Friend's Last Name`,
		email: `Friend's Email Address`,
		phone: `Friend's Phone Number`,
		relationship: `Relationship`,
		button: {
			cancel: "CANCEL",
			send: "SEND",
		},
		toast: {
			success: "Great! Your invitation is on its way.",
			noInvites:
				"You have successfully shared an invite for K4Community Plus. \n This is a demo. No invites will actually be sent.",
		},
	},
	noResults: {
		alt: "No results found",
	},
	components: {
		darkMode: {
			label: "Dark Mode",
		},
		redirect: {
			title: "Logged in successfully!",
			link: "Click here if you haven't been redirected",
			message:
				"You are logged into your K4Connect account. You can now go back to the original site that redirected you here to login.",
		},
	},
	firebase: {
		'auth/email-already-in-use': "This email address is already in use. Please try logging in again.",
		'auth/weak-password': "Your password is too weak, please enter a stronger password.",
		"auth/account-exists-with-different-credential":
				"You previously signed in with one of the social accounts below. Please click the correct social login to get started.",
		"auth/invalid-action-code":
				"Invalid password reset code. Please request a new one.",
		"auth/too-many-requests":
					"You’ve reached the maximum number of failed login attempts.\nPlease press “Forgot Password” to reset your login credentials.",
		"auth/wrong-password": "The password is incorrect. Please try again",
		"auth/popup-blocked": "Your browser is currently set to block popups for K4Connect. Please allow popups to log in.",
		"auth/requests-from-referer": "Requests from this domain are blocked.",

	}
};

export { strings };
