import Vue from 'vue'
import moment from 'moment';
import App from './App.vue'
import vuetify from './plugins/vuetify';
import strings from './plugins/strings';
import cypress from './plugins/cypress';
import bugsnag from './plugins/bugsnag';
import rules from './plugins/rules';
import store from './store'
import router from './router'
import VueSnip from 'vue-snip';
import env from '@/api/env';
import firebase from '@/api/firebase';

Vue.config.productionTip = false

Vue.use(strings);
Vue.use(rules);
Vue.use(bugsnag);

Vue.use(VueSnip);

if(env.name !== 'production')
{
	//add helper plugin to populate data-cy attributes
	Vue.use(cypress);
}

async function main()
{

	//initialize stores
	await store.dispatch('initialize');	
	router.initialize();

	new Vue({
		vuetify,
		store,
		router,
		render: h => h(App)
	}).$mount('#app');

	try {
		await firebase.migrate();
	} catch (e) {
		//error trying to get migration otp and refresh user
	}

	try {
		await store.dispatch('mobile/load');
	} catch (e) {
		//mobile load error
	}
	
	try {

		if(location.href.indexOf('logout') < 0) {
			await store.dispatch('user/load');
		}
		else if (store.getters['navigation/origin']) {
			await store.dispatch('user/logout');
		}
		
	} catch (e) {
		//user load will fail if no session, but thats ok - router will send to login page
	}


	await store.dispatch('navigation/ready', true, { root: true })
	
}

main();





Vue.filter('formatTime', function(value) {
	if (value) {
		return moment(new Date(value)).format('h:mm A')
	}
});

Vue.filter('formatAgo', function(value) {
	if (value) {
		const now = moment();
		const days = now.diff(new Date(value), "days");
		
		return (days < 0) ? 'Upcoming' : (days === 0) ? 'Today' : (days === 1) ? `${days} day ago` : `${days} days ago`
	}
});

Vue.filter('formatDate', function(value) {
	if (value) {
		
		const date = moment(value).startOf('day');
		const now = moment().startOf('day');
		const days = date.diff(now, "days");

		if(days < -1 || days > 1)
		{
			return date.format('MMMM Do YYYY');
		} else if(days === -1)
		{
			return 'Yesterday';
		} else if(days === 1) {
			return 'Tomorrow';
		} else {
			return 'Today'
		}
		
	}
});

Vue.filter('formatPhone', function (phone) {
    return phone.replace(/[^0-9]/g, '')
                .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
});
