import _ from 'lodash';
import { set } from "@/utils/vuex";
import Vuetify from '@/plugins/vuetify';


const state = () => ({
	route: null,
	visible: false,
	loading: false,
	overlay: false,
	ready: false,
	excluded: ["login"],

	//routes for both mobile and desktop
	routes: [
		{
			name: "Authed",
			path: "/redirect",
			view: "RedirectPage"
		},
		{
			name: "Migrate",
			path: "/migrate",
			view: "Migrate"
		},
		{
			name: "Login",
			path: "/login",
			view: "Login",
			items: [
				{
					name: "Login",
					path: "/login/standard",
					component: "login/StandardLogin",
				},
				{
					name: "Login",
					path: "/login/password",
					component: "login/PasswordLogin",
				},
				{ name: "Login", path: "/login/staff", component: "login/StaffLogin" },
				{ name: "Login", path: "/login/code", component: "login/CodeLogin" },
				{
					name: "Password Reset",
					path: "/login/reset",
					component: "login/PasswordReset",
				},
			],
		},
		{ name: "Logout", path: "/logout", view: "Logout" },
		{ name: "Signup", path: "/signup", view: "Signup" },
	],
});


const getters = {

	home()
	{
		return '/login/standard';
	},

	login()
	{
		return '/login';
	},

	signup()
	{
		return '/signup';
	},

	migrate()
	{
		return '/migrate';
	},

	origin() {
		return new URL(location.href).searchParams.get("origin");
	},

	isMobile(state, getters, rootState, rootGetters)
	{
		const info = rootGetters['mobile/info'];
		return info.platform && info.platform != 'web';
	},

	isPhone(state, getters)
	{
		return getters.isMobile && getters.isCompact;
	},

	isTablet(state, getters)
	{
		return getters.isMobile && !getters.isCompact;
	},

	isCompact() {
		return Vuetify.framework.breakpoint.xs;
	},
	isLoading(state)
	{
		return state.loading;
	},
	showOverlay(state)
	{
		return state.overlay
	},
	modalWidth(state, getters)
	{
		return (getters.isCompact) ? 300 : 450;
	},

	title(state, getters, rootState, rootGetters)
	{
		const { title, paramTitle, getterTitle } = state.route.meta;

		if(paramTitle)
		{
			let text = state.route.params[paramTitle];
			const category = state.route.params['category'];

			//special cases
			if (text === 'all' && paramTitle === 'tag' && category === 'Menu')
			{
				//dont show 'All Menus' for dining info
				text = `All Dining Info`;
			} else if (text === 'all' && paramTitle === 'tag' && category)
			{
				//all tags should and categories should return plural category
				text = `All ${category}s`;
			} else if (paramTitle === 'category')
			{
				//all tags should and categories should return plural category
				text = `${text}s`;
			}

			return _.startCase(text);

		} else if(getterTitle) {
			return rootGetters[getterTitle];
		}

		return title;
	},

	routes(state)
	{
		return state.routes;

	},
};

const mutations = {

	setVisible: set('visible'),
	setMobile: set('mobile'),
	setRoute: set('route'),
	setLoading: set('loading'),
	setOverlay: set('overlay'),
	setReady: set('ready')
};

const actions = {
	updateRoute({commit}, route)
	{
		commit('setRoute', route);
	},
	loading({commit}, value)
	{
		commit('setLoading', value);
	},
	overlay({commit}, value) 
	{
		commit('setOverlay', value)
	},
	ready({commit}, value) 
	{
		commit('setReady', value)
	}
};


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};

