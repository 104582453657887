import vuetify from "@/plugins/vuetify";
import Themes from "@/../public/themes/themes.json";
import _ from "lodash";
import { get, set } from "@/utils/vuex";

const state = () => ({
	current: null,
	themes: [],
	dark: false,
});

const getters = {
	availableThemes: () => Themes,
	themes: get("themes"),
	theme: (state) => state.themes[state.current],

	logo: (s, getters) =>
		_.get(getters.theme, "images.logo", "logo-k4community.svg"),
	background: (s, getters) => _.get(getters.theme, "images.background"),
	"background-dark": (s, getters) =>
		_.get(getters.theme, "images.background-dark"),

	style: (state, getters) => ({
		..._.get(state.themes, "default.style", {}),
		..._.get(getters.theme, "style", {}),
	}),
	logoHeight(state, g, rs, rootGetters) {
		if (state.current === "k4ops") return "unset";
		return rootGetters["navigation/isCompact"] ? 64 : 96;
	},
};

const mutations = {
	setCurrent(state, theme) {
		state.current = theme;
		theme = state.themes[theme];
		if (theme) {
			Object.keys(theme.colors.dark).forEach((i) => {
				vuetify.framework.theme.themes.dark[i] = theme.colors.dark[i];
			});
			Object.keys(theme.colors.light).forEach((i) => {
				vuetify.framework.theme.themes.light[i] = theme.colors.light[i];
			});
		}
	},
	setThemes: set("themes"),
	setDark(state, value) {
		state.dark = value;
		vuetify.framework.theme.dark = value;
	},
};

const actions = {
	init: async ({ commit }) => {
		const themes = {};
		for (const theme of Themes) {
			const module = await import(`@/../public/themes/${theme}.json`);
			themes[theme] = module.default;
		}
		commit("setThemes", themes);
	},

	setTheme: async ({ commit, dispatch }, theme) => {
		await dispatch(
			"settings/save",
			{ name: "theme", value: theme },
			{ root: true }
		);

		commit("setCurrent", theme);
	},

	setDark: async ({ commit, dispatch }, value) => {
		await dispatch("settings/save", { name: "dark", value }, { root: true });
		commit("setDark", value);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
