import { Bridge } from '@k4connect/capacitor-plugin'
import { set } from "@/utils/vuex";

const state = () => ({
	settings: null
});


const getters = {
	value(state)
	{
		return (key) => {
			if(state.settings)
			{
				return state.settings[key];
			}
		}
	}
};

const mutations = {
	setSettings: set("settings")
};

const actions = {

	init: async ({commit, rootGetters}) => {
		
		if(rootGetters['mobile/isK4App'])
		{
			const settings = await Bridge.getSettings();
			if(settings)
			{
				commit('setSettings', settings);
			}
		} else {

			const settings = {};

			//load all from web localStorage, use K4_ prefix to filter
			for(let key in localStorage)
			{
				if(key.indexOf('K4_') === 0)
				{
					const actualKey = key.slice(3);
					settings[actualKey] = localStorage.getItem(key);
				}
			}

			commit('setSettings', settings);

		}

	},

	save: async ({dispatch, rootGetters},  { name, value }) => {

		if(rootGetters['mobile/isK4App'])
		{
			await Bridge.setSetting({name, value: String(value)});
		} else {
			//save to web localStorage
			localStorage.setItem(`K4_${name}`, value);
		}
	
		await dispatch('init');
	}
	
};


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};