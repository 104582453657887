import env from '@/api/env';
import axios from 'axios';
import store from '@/store/index';
import jwt_decode from "jwt-decode";

export default {

	//set the jwt using K4 sso username and password
	async login(id, password)
	{

		console.log('SSO login...');

		const creds = btoa(id + ':' + password);

		const response = await this.post(env.auth(), null, {
			headers: {
				'Authorization': 'Basic ' + creds, 
				'Content-Type': 'application/x-www-form-urlencoded',
				'Accept': 'text/plain'
			},
			withCredentials: true
		});

		if(response.data === 'Access Denied' || response.status !== 200)
		{
			throw new Error('Access Denied');
		}

		//mobile - save username and password to store
		const isK4App = store.getters['mobile/isK4App'];
		if(isK4App)
		{
			await store.dispatch('mobile/saveCredentials', { username: id, password });
		}

		this.jwt = response.data;
		
		return response.data

	},

	//check if user has SAML enabled
	async saml(email) {
		let response;
		try {
			response = await this.get(`${env.auth()}/saml/login?email=${email}`);
		} catch (e) {
			if (e.response.status === 404) return false;
			else throw e;
		}

		return response;
	},

	//generate OTP to pass JWT to other sites
	async otp(payload)
	{

		console.log('Generating otp...');

		const jwt = this.jwt;
		const url = `${env.auth()}/otp`;

		const response = await this.post(url, {
			payload
		}, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${jwt}`,
			}
		});

		if(response.status !== 200)
		{
			throw new Error('Access Denied');
		}

		const { otp } = response.data;

		return otp;

	},

	async externalOtp(token)
	{
		
		console.log('storing jwt with external token...');

		const jwt = this.jwt;
		const url = `${env.auth()}/otp/external`;

		const response = await this.post(url, {
			payload: jwt,
			token
		}, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${jwt}`,
			}
		});

		if(response.status !== 200)
		{
			throw new Error('Access Denied');
		}

	},

	async validateNonce(nonce)
	{

		console.log('Validating nonce...');

		const url = `${env.auth()}/otp/configuration/validate`;

		const response = await this.post(url, {
			nonce
		}, {
			withCredentials: true,
			headers: {
				'Content-Type': 'application/json',
			}
		});

		console.log('validateNonce response', response);

		if(response.status !== 200)
		{
			throw new Error('Access Denied');
		}

	},

	//exchange code for K4 sso username and password
	async code(code)
	{

		console.log('Redeeming code...');

		const url = `${env.sso()}/registerInvite`;

		const response = await this.post(url, {
			inviteCode: code
		}, {
			headers: {
				'Content-Type': 'application/json',
			}
		});

		if(response.status !== 200)
		{
			throw new Error('Access Denied');
		}

		const { guid, password, device } = response.data;

		
		const isK4App = store.getters['mobile/isK4App'];
		
		if(isK4App)
		{
			await store.dispatch('mobile/saveCredentials', { username: guid, password });
			await store.dispatch('settings/save', { name: 'deviceId', value: device });
		}
		

		this.jwt = await this.login(guid, password);

		const user = jwt_decode(this.jwt);
		const { communities, roles } = user;
		
		//used to determine if user is staff to skip sign up flow
		return {
			id: guid, communities, roles
		}

	},


	async email(email)
	{

		console.log('Check for existing Firebase user...');

		const response = await this.post(`${env.auth()}/firebase/checkEmail`, {
			email
		},{
			headers: {
				'content-type': 'application/json',
			}
		});

		return response.data;

	},

	async checkInvite(email)
	{

		console.log('Checking for SSO invite...');

		const response = await this.post(`${env.sso()}/checkForInvite`, {
			email
		},{
			headers: {
				'content-type': 'application/json',
			}
		});

		return response.data;

	},

	async firebase(firebaseIdToken)
	{

		console.log('Auth login with Firebase idToken...');

		const response = await this.post(`${env.auth()}/firebase/login`, {
			firebaseIdToken
		},{
			withCredentials: true,
			headers: {
				'content-type': 'application/json',
			}
		});

		this.jwt = response.data;

		return response.data;

	},

	async linkFirebase(firebaseIdToken)
	{
		console.log('Linking Firebase to auth...');

		const jwt = this.jwt;

		const response = await this.post(`${env.auth()}/firebase/link`, {
			firebaseIdToken,
			jwt
		},{
			headers: {
				'content-type': 'application/json',
			}
		});

		return response.data;

	},

	async getCustomToken()
	{
		console.log("Getting custom token...");

		const url = `${env.auth()}/customToken`;

		const response = await this.post(url, {}, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${this.jwt}`,
			}
		});

		if(response.status !== 200)
		{
			throw new Error('Access Denied');
		}

		const { token } = response.data;

		this.customToken = token;

		return token;

	},

	async claimOtp(otp) {
		const url = `${env.auth()}/otp/${otp}`;

		console.log('Claim OTP...', url);

		const response = await axios.get(url, { withCredentials: true });

		let { payload } = response.data;

		try {
			payload = JSON.parse(payload);
		} catch {
			// old otp format
		}

		return payload;
	},

	async post(url, body, options)
	{
		let response;

		store.dispatch('navigation/loading', true);
		

		try {
			response = await axios.post(url, body, options);
		} catch(e) {
			store.dispatch('navigation/loading', false);
			throw e;
		}

		store.dispatch('navigation/loading', false);
		return response;

	},

	async get(url, options) {
		let response;

		store.dispatch("navigation/loading", true);

		try {
			response = await axios.get(url, options);
		} finally {
			store.dispatch("navigation/loading", false);
		}

		return response;
	},
}
