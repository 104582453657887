
import { initializeApp } from 'firebase/app';

import { 
	getAuth, 
	signInWithEmailAndPassword, 
	sendPasswordResetEmail, 
	signOut, 
	checkActionCode,
	signInWithCustomToken, 
	confirmPasswordReset,
	onAuthStateChanged,
	GoogleAuthProvider,
	FacebookAuthProvider,
	createUserWithEmailAndPassword,
	OAuthProvider,
	signInWithCredential,
	indexedDBLocalPersistence,
} from 'firebase/auth';

import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

import auth from '@/api/auth';
import env from '@/api/env';

const firebaseConfig = env.firebaseConfig();

export default {

	async init()
	{
		this.app = initializeApp(firebaseConfig);
		this.auth = getAuth(this.app, { persistence: indexedDBLocalPersistence });

	},

	restore(observer)
	{
		return onAuthStateChanged(this.auth, observer)
	},

	async login(email, password)
	{

		const userCredential = await signInWithEmailAndPassword(this.auth, email, password);
		const firebaseIdToken = await userCredential.user.getIdToken();	
			
		return auth.firebase(firebaseIdToken);

	},

	async logout()
	{
		await signOut(this.auth);
	},

	async forgot(email, redirect)
	{

		const actionCodeSetings = {
			url: redirect
		}

		await sendPasswordResetEmail(this.auth, email, actionCodeSetings);

	},
	async reset(code, password)
	{

		const response = await checkActionCode(this.auth, code);
		await confirmPasswordReset(this.auth, code, password);
		return this.login(response.data.email, password);

	},

	async social(name, signup)
	{

		let result, provider, credential;
		switch(name)
		{
			case 'google':
				result = await FirebaseAuthentication.signInWithGoogle();
				credential = GoogleAuthProvider.credential(result.credential?.idToken);
				await signInWithCredential(this.auth, credential);
				break;
			case 'facebook':
				result = await FirebaseAuthentication.signInWithFacebook();
				credential = FacebookAuthProvider.credential(
					result.credential?.accessToken,
				);
				await signInWithCredential(this.auth, credential);
				break;
			case 'apple':
				result = await FirebaseAuthentication.signInWithApple( {skipNativeAuth : true });
				provider = new OAuthProvider('apple.com');
				credential = provider.credential({
					idToken: result.credential?.idToken,
					rawNonce: result.credential?.nonce,
				});

				await signInWithCredential(this.auth, credential);
				break;
		}

		const tokenResult = await FirebaseAuthentication.getIdToken();

		try {

			if(signup) {
				await auth.linkFirebase(tokenResult.token);
			}

			await auth.firebase(tokenResult.token);

		} catch(e) {

			if(e?.response?.status === 401) {
				FirebaseAuthentication.deleteUser()
			}

			throw e;
			
		}
		

	},

	async createFirebaseUser(email, password)
	{

			//keep newUser to block restoreUserObserver in user store
			this.newUser = await createUserWithEmailAndPassword(this.auth, email, password)
			const { user } = this.newUser;
			const fireBaseIdToken = await user.getIdToken();
			await auth.linkFirebase(fireBaseIdToken);
			await auth.firebase(fireBaseIdToken);

	},

	async useCustomToken(customToken)
	{
		try {
			await signInWithCustomToken(this.auth, customToken);
		} catch (e) {
			console.log(e)
		}
	},

	//called from main before user load, attempts to restore firebase refresh token
	async migrate()
	{
		
		const params = new URLSearchParams(window.location.search);
		const customToken = params.get('migrate');

		if(!customToken) {
			return;
		}

		try {
			await this.useCustomToken(customToken);
		} catch (e) {
			console.log(e)
		}
		
	},

	app: null,
	auth: null,
	user: null

	

}
