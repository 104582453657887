import { Bridge } from '@k4connect/capacitor-plugin'
import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import { App } from '@capacitor/app'

import env from '@/api/env'
import axios from 'axios'

import auth from '@/api/auth';

import { set, get } from "@/utils/vuex";

const state = () => ({
	native: false,
	deviceInfo: {},
	appInfo: {},
	migration: null,
	migrationManifest: null
});


const getters = {
	
	isK4App: get('native'),
	credentials: get('credentials'),
	info: get('deviceInfo'),
	appInfo: get('appInfo'),
	migration: get('migration'),
	migrationManifest: get('migrationManifest'),
	migrationAllowed(state)
	{
		return !!state.migrationManifest;
	}
};

const mutations = {
	setNative: set("native"),
	setCredentials: set("credentials"),
	setDeviceInfo: set("deviceInfo"),
	setAppInfo: set("appInfo"),
	setMigration: set("migration"),
	setMigrationManifest: set("migrationManifest")
};

const actions = {
	init: async({commit}) => {
		const deviceInfo = await Device.getInfo();
		commit('setDeviceInfo', deviceInfo);

		const appInfo = await App.getInfo();
		commit('setAppInfo', appInfo);
	},

	load: async ({commit, state}) => {
		
		//loads first, gets credentials from keychain on native

		const native = Capacitor.isNativePlatform();
		commit('setNative', native);


		if(native)
		{

			try {
				const credentials = await Bridge.loadKeychain();
				commit('setCredentials', credentials);

			} catch(e) {
				//error initializing bridge
			}

			try {
				const url = env.migration();
				const { data } = await axios.get(url);
				const { id } = state.appInfo;
				commit('setMigrationManifest', data[id]);
			} catch(e) {
				console.error(e);
			}
			
		
		}

		

	},

	saveCredentials: async ({commit, state}, credentials) => {
		
		if(state.native)
		{
			await Bridge.saveKeychain(credentials);
			commit('setCredentials', credentials);
		}
		
	},


	// if deep link exists, redeem for credentials and set jwt in auth
	checkForDeepLink: async({state, commit, dispatch}) => {

		if(!state.native)
		{
			return;
		}

		return new Promise((resolve) => {

			console.log('Checking for deeplink...');

			let attempts = 3;

			const timer = setInterval(async () => {
				
				try {
					
					const { url } = await Bridge.getDeeplink();
					
					if(url)
					{
						clearInterval(timer);

						const { url } = await Bridge.getDeeplink();
						const link = new URL(url);

						const action = link.searchParams.get('action');
						const code = link.searchParams.get('code');
						const redirect = link.searchParams.get('redirect');
						const otp = link.searchParams.get('otp');

						if(action === 'migration' && redirect)
						{
							const params = {};
							for (const [key, value] of link.searchParams.entries()) {
								params[key] = value;
							}
							commit('setMigration', params);
						} else if(action === 'share' && otp)
						{
							console.log('Shared Credentials Deeplink found...');
							dispatch('user/claimShared', otp, { root: true });
						} else if(code)
						{
							console.log('Code Deeplink found...');
							await auth.code(code);
						}

						resolve();
					}
					

				} catch(e) {
					//no link, invalid link
				}

				attempts--;

				if(attempts <= 0)
				{
					clearInterval(timer);
					resolve();
				}
				
			}, 500);

		});
		
	},

	openDeeplink: async(ctx, link) => {
		await Bridge.openDeeplink({ link });
	},

	clearDeeplink: async() => {
		await Bridge.clearDeeplink({});
	},
	
};


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};