import { set } from '@/utils/vuex';

const state = () => ({
	current: null,
	color: null,
	timeout: null
});


const getters = {

};

const mutations = {
	
	setCurrent(state, text)
	{
		state.current = text;
		clearTimeout(state.timeout);

		state.timeout = setTimeout(() => {
			state.current = null;
		}, 8000);
	},
	setColor: set('color')
};

const actions = {
	error({commit}, text)
	{
		commit('setColor', 'error');
		commit('setCurrent', text);
	},

	success({commit}, text)
	{
		commit('setColor', 'success');
		commit('setCurrent', text);
	},

	info({commit}, text)
	{
		commit('setColor', 'info');
		commit('setCurrent', text);
	}
};


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};