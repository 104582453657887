import auth from '@/api/auth';
import firebase from '@/api/firebase';
import env from '@/api/env';
import router from '../../router'
import { set, get } from "@/utils/vuex";
import _ from 'lodash';
import Bugsnag from '@bugsnag/js'

const state = () => ({
	email: null,
	otp: null,
	customOtp: null,
	restoredJwt: null
});

const STAFF_ROLE = 4;
const K4LOGIN = true;
let firebaseTimeout, firebaseUnsub;

const getters = {

	otp: get("otp"),
	email: get("email"),
	customOtp: get("customOtp"),
};

const mutations = {
	setEmail: set("email"),
	setOtp: set("otp"),
	setCustomOtp: set("customOtp"),

	reset(state)
	{
		state.email = null;
		state.otp = null;
	}
};

const actions = {

	init: async () => {
		await firebase.init();
	},

	load: async ({ dispatch, rootGetters }) => {

		//this will re-enter on resume

		//load overlay
		dispatch('navigation/overlay', true, { root: true });

		//check for deeplink, will set auth jwt if successful
		await dispatch('mobile/checkForDeepLink', null, { root: true });
		

		//try to get keychain, on mobile gets from device, on web needs valid JWT from firebase login above
		const credentials = await rootGetters['mobile/credentials'];

		//if we got credentials, login will set auth JWT
		if(credentials)
		{
			console.log('Login with stored mobile keychain...');
			try {
				await auth.login(credentials.username, credentials.password);
			} catch(e) {
				//credentials didnt work
			}

		}

		if(auth.jwt)
		{
			//a method successfully set auth jwt
			dispatch('finishLogin');
		} else {

			// ONLY setup firebase subscribe if credential login failed!
			// listen to firebase auth state, can end up with auth having jwt when observer fires
			firebaseUnsub = firebase.restore((user) => dispatch('restoreUserObserver', user));

			const skipFirebaseTimeout = new URLSearchParams(location.search).get(
				"skipAuto"
			) === "true";
			if (!skipFirebaseTimeout) {
				//user must login, dismiss overlay after 3 seconds (allow firebase time)
				firebaseTimeout = setTimeout(() => dispatch("dismissOverlay"), 3000);
			}
		}

	},

	finishLogin: async ({ commit, rootGetters, dispatch }, isFirebase = false) => {
		console.log('Finishing login...');

		const jwt = auth.jwt;
		const origin = rootGetters["navigation/origin"];
		let customOtp;

		if (isFirebase && !origin) {
			const customToken = await auth.getCustomToken();
			customOtp = await auth.otp(JSON.stringify({ jwt, customToken }));
		}

		//we have a JWT in auth, redirect with OTP
		const otp = await auth.otp(jwt);

		if (origin) {
			return window.top.postMessage({ otp, valid: true, K4LOGIN }, origin);
		}

		commit('setOtp', otp);
		commit('setCustomOtp', customOtp);


		const migration = rootGetters['mobile/migration'];

		if(migration)
		{
			router.replace('/migrate');
		} else if(otp || customOtp)
		{
			router.replace('/redirect');
			
		} else {
			Bugsnag.notify('finishLogin completed with no OTP or CustomOTP!', null, ()=>{
				dispatch("dismissOverlay");
			});
		}
		
	},

	finishLoginExternal: async (args, { token, nonce }) => {
		console.log('Finishing external login...');
		//we have a JWT in auth, redirect with OTP
		if(nonce) {
			try {
				await auth.validateNonce(nonce);
				await auth.externalOtp(token);
			} catch(e) {
				console.log('unable to validate nonce')
			}
			
		} else {
			await auth.externalOtp(token);
		}
		
		router.replace('/redirect');
	},

	login: async ({dispatch}, { email, password }) => {

		console.log('User logged in with email and password...');
		await auth.login(email, password);

		//login throws, so if we are successful, proceed to OTP
		await dispatch('finishLogin');

	},

	loginExternal: async ({dispatch}, { email, password, token, nonce }) => {
		//token is external token we send to otp endpoint, 
		//used to retrieve jwt by external integration after auth
		await auth.login(email, password);
		await dispatch('finishLoginExternal', {token, nonce});
	},

	shareCredentials: async ({ dispatch, rootGetters }, redirect) => {

		const jwt = auth.jwt;
		const credentials = await rootGetters['mobile/credentials'];
		
		const { currentUser } = firebase.auth;
		
		let shareOtp;

		if (currentUser) {

			const customToken = await auth.getCustomToken();

			shareOtp = await auth.otp(JSON.stringify({ type: 'firebase', jwt, customToken }));

		} else if(credentials) {
			
			shareOtp = await auth.otp(JSON.stringify({ type: 'credentials', credentials }));

		} else {
			throw 'No shareable credentials found.'
		}

		const link = `${redirect}://login?action=share&otp=${shareOtp}`;

		await dispatch('mobile/openDeeplink', link, { root: true })

	},

	claimShared: async ({ dispatch }, otp) => {

		const payload = await auth.claimOtp(otp);

		const { type } = payload;
		console.log('Claim shared...', type)
		if(type === 'credentials')
		{
			console.log('Logging in with shared credentials...');
			const { username, password } = payload.credentials;
			await dispatch('login', { email: username, password});

		} else if(type ==='firebase') {

			console.log('Restoring shared firebase...');
			const { customToken } = payload;
			await firebase.useCustomToken(customToken);
			window.location.reload();
		} else {
			throw 'Invalid shared credentials type ' + type;
		}
		
	},

	validateNonce: async (_, nonce) => {
		await auth.validateNonce(nonce);
	},

	samlLogin: async (ctx, email) => {
		return auth.saml(email);
	},

	logout: async ({commit, rootGetters, dispatch}) => {
		let logout = true;

		try {
			await firebase.logout();

			const isK4App = rootGetters['mobile/isK4App'];

			//TODO - future add deleteCredentials to native bridge
			if(isK4App)
			{
				await dispatch('mobile/saveCredentials', { username: 'null', password: 'null' }, { root: true });
			}

			console.log('Logged out...');
		} catch (e) {
			// logout fail
			logout = false;
		} finally {
			const origin = rootGetters["navigation/origin"];
			if (origin) {
				window.top.postMessage({ K4LOGIN, logout }, origin);
			}

			commit('reset');
		}
	},

	code: async ({dispatch, rootGetters}, code) => {

		const { roles } = await auth.code(code);
		const isStaff = _.includes(roles, STAFF_ROLE);

		const isK4App = rootGetters['mobile/isK4App'];

		//on web for non-staff, go to firebase signup
		if(!isStaff && !isK4App) {
			router.replace('/signup');
			console.log('User logged in with code...');
		} else {
			//login throws, so if we are successful, proceed to OTP
			console.log('Staff logged in with code...');
			await dispatch('finishLogin');
		}
	},

	email: async ({commit}, email) => {

		//exception is thrown if not firebase password login
		await auth.email(email);

		//if found (200), save email for password login
		commit('setEmail', email);
		router.push('/login/password');

	},

	//send invite and on success go to code screen
	checkInvite: async({commit}, email) => {
		await auth.checkInvite(email);
		commit('setEmail', email);
		router.push('/login/code')
	},

	firebaseLogin: async ({state, dispatch}, password) => {

		console.log('User logged in with Firebase email and password...');

		await firebase.login(state.email, password);

		//login throws, so if we are successful, proceed to OTP
		await dispatch('finishLogin', true);

	},

	firebaseForgot: async ({state}, redirect) => {
		await firebase.forgot(state.email, redirect);
	},

	firebaseReset: async ({dispatch}, {code, password}) => {

		console.log('Setting Firebase password after reset...');


		await firebase.reset(code, password);

		//reset throws, so if we are successful, proceed to OTP
		await dispatch('finishLogin', true);
	},

	firebaseSocial: async ({dispatch}, {name, signup}) => {

		console.log('User logged in with Firebase social...');

		await firebase.social(name, signup);

		//social throws, so if we are successful, proceed to OTP
		await dispatch('finishLogin', true);

	},

	createUser: async({dispatch}, {email, password}) => {

		console.log('Creating Firebase user...');

		await firebase.createFirebaseUser(email, password);
		//reset throws, so if we are successful, proceed to OTP
		await dispatch('finishLogin', true);
	},

	resetPassword()
	{
		const resetUrl = `${env.teamhub()}/login/request-reset`
		return window.open(resetUrl);
	},

	//sets auth jwt if firebase refresh is successful
	async restoreUserObserver({ dispatch }, user)
	{
		clearTimeout(firebaseTimeout);
		firebaseUnsub?.(0);

		//user was loaded by Firebase, but not from a user login or user creation. Get idToken and login with it
		if(user && !auth.jwt && !auth.newUser)
		{
			dispatch("navigation/overlay", true, { root: true });

			try {
				console.log("Login with Firebase refresh...");

				const firebaseIdToken = await user.getIdToken();
				await auth.firebase(firebaseIdToken);
				await dispatch("finishLogin", true);
			} catch (e) {
				// user was never found and could not refresh
			}
		}

		dispatch("dismissOverlay");
	},

	dismissOverlay({ state, dispatch, rootGetters }) {
		if (!state.otp) {
			console.log("No firebase credentials found...");

			const origin = rootGetters["navigation/origin"];
			if (origin) {
				window.top.postMessage({ valid: false, K4LOGIN }, origin);
			}

			dispatch("navigation/overlay", false, { root: true });
		}
	}

};


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
