import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/lib/services/goto";

import store from "@/store/index";
import _ from "lodash";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		redirect: () => {
			return store.getters["navigation/home"];
		},
	},
	{
		//handle firebase resets
		path: "/forgot-password",
		redirect: (to) => {
			const { query } = to;
			return { path: "/login/reset", query };
		},
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior: (to, from, savedPosition) => {
		let scrollTo = 0;

		if (to.hash) {
			scrollTo = to.hash;
		} else if (savedPosition) {
			scrollTo = savedPosition.y;
		}

		return goTo(scrollTo, { duration: 0 });
	},
});

router.initialize = () => {
	buildRoutes(store.getters["navigation/routes"]);
};

router.beforeEach(async (to, from, next) => {
	//store last route
	router.lastRoute = from;

	const query = _.merge(from.query, to.query);
	if (!store.getters["theme/availableThemes"].includes(query.theme)) {
		query.theme = "default";
	}
	if (!("dark" in query)) {
		query.dark = "false";
	}


	store.dispatch("navigation/updateRoute", to);
	store.dispatch("theme/setTheme", query.theme);
	store.dispatch("theme/setDark", query.dark !== "false");

	const isLogin = to.matched.some((route) => route.name === "login");
	const isSignup = to.matched.some((route) => route.name === "signup");
	const isLogout = to.matched.some((route) => route.name === "logout");
	const isMigrate = to.matched.some((route) => route.name === "migrate");
	const isStaff = to.path === "/login/staff";
	const isRedirect = to.matched.some((route) => route.name === "redirect");

	if(isStaff && ("context" in query)) {
		query.role = "staff"
	}

	

	if (isSignup || isRedirect || isMigrate) {
		next();
	} else if (isLogin) {
		//if route is not staff login, but the staff role was passed in, change to /login/staff
		if (!isStaff && query.role === "staff") {
			next({ path: "/login/staff", query });
		} else {
			next();
		}
	} else if(isLogout) {
		next();
	} else {
		next({ path: "/login", query });
	}
});

function buildRoutes(list, children) {
	_.each(list, (item) => {
		const { path, name, view, component, items, paramTitle, getterTitle } =
			item;

		const route = {
			name: path.replace(/^\/+/g, ""),
			path: path,
			meta: {
				title: name,
				paramTitle,
				getterTitle,
			},
			children: [],
		};

		if (view) {
			//dynamic load views
			route.component = () =>
				import(/* webpackChunkName: "[request]" */ `@/views/${view}.vue`);
		} else if (component) {
			//dynamic load components
			route.component = () =>
				import(
					/* webpackChunkName: "[request]" */ `@/components/${component}.vue`
				);
		}

		//build routes for menu items
		if (items) {
			buildRoutes(items, route.children);
		}

		//add to nested routes on recursive calls
		if (children) {
			children.push(route);
		} else {
			router.addRoute(route);
		}
	});
}

export default router;
