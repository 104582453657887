import axios from 'axios';

export const getWebVersion = async () => {
	
	try {
		const { data } = await axios.get('/version.json');
		return data.version;
	} catch(e) {
		//fail to load, will happen on local dev without building
	}
};
