<template>
	<v-snackbar :color="color" :timeout="timeout" top v-model="model">{{current}}</v-snackbar>
</template>

<script>
import { mapState } from 'vuex';
export default {

	name: 'AlertBanner',

	watch: {
		current()
		{
			this.model = !!this.current;
		}
	},

	computed: {
		...mapState('alerts', ['current', 'color'])
	},

	data: () => ({
		model: false,
		timeout: 8000
	}),
}
</script>