import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import Vue from 'vue'
import store from "@/store/index";
import env from "@/api/env";
import { getWebVersion } from "@/utils/version";


let version;

export default Bugsnag.start({
  apiKey: "90300af7b880f5af7b5634736f1677ac",
  plugins: [new BugsnagPluginVue()],
  autoNotify: true,
  releaseStage: env.name() || 'production',

  onError: async event => {
    let customOtp = null;
    let otp = null;
    let email = null
    try {
      customOtp = store.getters["user/customOtp"];
      otp = store.getters["user/otp"];
      email = store.getters["user/email"];
    } catch (e) {
      // Don't throw no matter what!
    }


	try {
		if(!version)
		{
			//get version once
			version = await getWebVersion();
		}

		event.app.version = version;
  
	} catch (e) {
		// Don't throw no matter what!
	}
    

    event.addMetadata("user", {
      context: {
        otp, customOtp
      },
    });
    event.setUser(null, email, null)


    return event;
  }
});

const bugsnagVue = Bugsnag.getPlugin('vue')
bugsnagVue.installVueErrorHandler(Vue)
