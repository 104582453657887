import Vue from 'vue'
import Vuex from 'vuex'
import { forEachModule } from "@/utils/vuex";
import { App } from '@capacitor/app';

Vue.use(Vuex)

import user from '@/store/modules/user';
import settings from '@/store/modules/settings';
import navigation from '@/store/modules/navigation';
import alerts from '@/store/modules/alerts';
import mobile from '@/store/modules/mobile';
import theme from '@/store/modules/theme';


const modules = {
	navigation,
	user,
	settings,
	alerts,
	mobile,
	theme
};

const actions = {
	async initialize({ dispatch }) {
		await forEachModule(modules, { dispatch }, "init");
	},
	async resetState({ commit }) {
		await forEachModule(modules, { commit }, "resetState");
	},
};

const store = new Vuex.Store({ actions, modules });

App.addListener('resume', async () => {
	await store.dispatch('user/load');
});

export default store;
