<template>
	<v-app v-if="!origin" id="app">
		<AlertBanner />
		<LoadingOverlay />
		<v-main>
			<v-container v-if="ready" >
				<router-view />
			</v-container>
			<div v-else :class="classes" :style="bg" ></div>
		</v-main>
	</v-app>
</template>

<script>
import AlertBanner from "@/components/util/AlertBanner.vue";
import LoadingOverlay from "@/components/util/LoadingOverlay.vue";
import _ from "lodash"
import { mapState, mapGetters } from 'vuex';

export default {
	computed: {
		...mapState('navigation', ['ready']),
		...mapState('theme', ['current', 'dark']),
		...mapGetters('theme', ['background', 'background-dark']),
		...mapGetters('navigation', ['origin']),
		classes() {
			const classes = {
				login: true,
				fullscreen: true
			};
			return _.chain(classes).pickBy().keys().join(" ").value()
		},
		bg() {
			let bg = this.dark ? this['background-dark'] : this.background;
			return bg ? `background-image: url(${require(`@/assets/themes/${bg}`)});` : '';
		}
	},

	components: {
		AlertBanner,
		LoadingOverlay
	},
	watch: {
		async $route(to, from) {
			if (to.path !== from.path && !_.isEqual(from.query, to.query)) {
				// For some reason calling a builder function for query breaks the replace method
				const query = _.merge(from.query, to.query)
				if (!this.$store.getters["theme/availableThemes"].includes(query.theme)) {
					query.theme = "default";
				}
				if (!("dark" in query)) {
					query.dark = "false";
				}

				this.$router
					.replace({ query })
					.catch((error) => {
						if (error.name !== "NavigationDuplicated") {
							throw error;
						}
					});
			}
		},
	},
};
</script>

<style>
body {
	background-color: black;
}
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	word-break: keep-all !important;
}

.v-card__title {
	word-break: keep-all !important;
}

.v-main {
	background-color: var(--v-background-base) !important;
}

.v-toolbar__extension {
	padding: 0px !important;
}

.v-list-item--active {
	color: var(--v-primary-base) !important;
}

.v-input .v-input__icon--prepend-inner {
	margin-right: 10px;
}

.login {
	background-size: cover;
	max-width: none !important;
	background-position: center center;
}

.fullscreen {
	height: 100vh;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}
</style>
