<template>
  <div class="text-center">
    <v-overlay :value="showOverlay" :opacity="opacity">
      <v-row v-if="isMobile" class="mb-5">{{ $strings.spinner }}</v-row>
      <v-progress-circular
        indeterminate
        :size="size"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'SocialButton',
	props: {
		name: String,
		signup: Boolean
	},
	computed: {
		...mapGetters('navigation', ['showOverlay', 'isMobile']),
    opacity()
    {
      return this.isMobile ? .8 : .3
    }
	},
	methods: {
		
	},
  data: () => ({
    size: 32,
  })
}
</script>

<style>
.socialButtonLabel {
	opacity: 0.7;
}
</style>
