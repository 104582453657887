export default {

	api()
	{
		return this.url('api', '/v3/graph/graphql');	
		
	},

	auth()
	{
		return this.url('auth-v2', '');	
	},

	sso()
	{
		return this.url('api', '/v2/sso');		
	},

	remote()
	{
		return this.url('remote', '');
	},

	teamhub()
	{
		return this.url('teamhub', '')
	},

	k4community()
	{
		const name = this.name();
		const suffix = (name === 'production') ? '' : `${name}.`;
		return `https://${suffix}k4community.com`;
	},

	migration()
	{
		const name = this.name();
		return `https://k4connect-shared.s3.amazonaws.com/whitelabel/manifest-${name}.json`;
	},

	url(service, path)
	{
		const suffix = this.suffix();
		return `https://${service}${suffix}.k4connect.com${path}`;
	},


	suffix()
	{
		const name = this.name();
		return (name === 'production') ? '' : `-${name}`;

	},

	permittedHosts()
	{
		const name = this.name();
		const suffix = this.suffix();

		const permitted = [
			`teamhub${suffix}.k4connect.com`,
			`ops${suffix}.k4connect.com`,
			`${(name === 'production') ? 'www' : name }.k4community.com`,
			`office${suffix}.k4connect.com`,
			`data${suffix}.k4connect.com`
		]

		if(process.env.NODE_ENV === 'development')
		{
			permitted.push('localhost');
		}

		return permitted;
	},

	name()
	{
		const { href } = location;

		const web = href.indexOf('k4community') >  -1;

		if(!web && process.env.VUE_APP_BUILD_TARGET)
		{
			return process.env.VUE_APP_BUILD_TARGET;
		}

		if(location.origin.indexOf('localhost') > -1 || location.origin.indexOf('-dev') >  -1)
		{
			return 'dev';
		} else if(location.origin.indexOf('-staging') >  -1)
		{
			return 'staging';
		} else {
			return 'production';
		}
	},
	
	firebaseConfig() {
		const name = this.name();

		let config;

		switch (name) {
			case 'dev':
				config = {
					apiKey: 'AIzaSyAcvGyFCM5i1fGgAU25kBqtIZjnHXjAq4s',
					authDomain: 'k4community-dev.firebaseapp.com',
					databaseURL: 'https://k4community-dev.firebaseio.com',
					projectId: 'k4community-dev',
					storageBucket: 'k4community-dev.appspot.com',
					messagingSenderId: '832309708685',
					appId: '1:832309708685:web:f68bf4cdc785c9aaa5dfc4',
					measurementId : 'G-DDCP8DQQL2'
				};
				break;
			case 'staging':
				config = {
					apiKey: 'AIzaSyCLToVWu8PawebBwWj3ejdURECxwrfk2XQ',
					authDomain: 'k4community-staging.firebaseapp.com',
					databaseURL: 'https://k4community-staging.firebaseio.com',
					projectId: 'k4community-staging',
					storageBucket: 'k4community-staging.appspot.com',
					messagingSenderId: '445754308968',
					appId: '1:445754308968:web:3dade148bf491371727edd',
					measurementId: 'G-BS2NZFR2N4'
				};
				break;
			case 'production':
				config = {
					apiKey: 'AIzaSyA-XQJF_V7kVs9grDvfRFnFfl3Aokxg06Q',
					authDomain: 'k4community20.firebaseapp.com',
					databaseURL: 'https://k4community20.firebaseio.com',
					projectId: 'k4community20',
					storageBucket: 'k4community20.appspot.com',
					messagingSenderId: '490672454147',
					appId: '1:490672454147:web:d82cd705830b55dff52c36',
					measurementId: 'G-LZBV586MBH'
				};
				break;
			default:
				throw new Error('Unknown environment');
		}

		return config

	}
}